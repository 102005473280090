// src/components/Feed.js

import React, { useContext } from "react";
import { VideosContext } from "../context/VideosContext";
import Carousel from "./Carousel";
import Videos from "./Videos";
import AcceptCookiesModal from "./AcceptCookiesModal";

const Feed = () => {
  const { videos , cameras} = useContext(VideosContext);

  return (
    <>
      <AcceptCookiesModal />
      <div className="h-screen grid grid-rows-3 gap-8">
        <div className="row-span-2">
          {/* <Carousel /> */}
        </div>
        <div className="row-span-1">
          <Videos videos={videos} />
        </div>
      </div>
    </>
  );
};

export default Feed;
