import axios from 'axios';

//#export const BASE_URL = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=HfgIFGbdGJ0&key=AIzaSyBZTGkP_dy_nQBepjsNdLocLO62Siq6hAQ';
export const BASE_URL = 'https://youtube.googleapis.com/youtube/v3/';

const options = {
  params: {
    maxResults: 50,
    key: "AIzaSyBZTGkP_dy_nQBepjsNdLocLO62Siq6hAQ"
  },
  headers: {
    'Accept': 'application/json',
  },
};

export const fetchFromYoutubeAPI = async (url) => {
    const fullUrl = `${BASE_URL}`+url;
    console.log ("full url is " + fullUrl);
    const { data } = await axios.get(fullUrl, options);
    for (let i=0; i<data.items.length;i++) {
        console.log ("data.items is " + data.items[i]);
    }
  return data;
};
