import React, { createContext, useState, useEffect } from "react";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import axios from "axios";

export const VideosContext = createContext();

export const VideosProvider = ({ children }) => {
  const [videos, setVideos]   = useState(null);
  //const [cameras, setCameras] = useState(null);
  const selectedCategory = "live webcam";

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + "camera/",
  });


  const addToDatabaseHandler = (props) => {
    client1
      .post("", {
        camName: props.title,
        camUrl: props.url,
        camLoc: "New York",
        metadata: props.description
      })
      .then((response) => {
        setOpen(true);
      })
      .catch((error) => {
        // Error
        setOpenError(true);
        console.log(error.request.responseText);
      });
  };



  useEffect(() => {
      //reset current set
      setVideos(null);
      //Fetch videos from youtube. 
      fetchFromAPI(`search?part=snippet&q=${selectedCategory}`).then((data) => {
      //set the result


       
      //setCameras(cameras)
      setVideos(data.items);
      console.log ("xyz marker");
      });

  }, [selectedCategory]);

  return (
    <VideosContext.Provider value={{ videos, setVideos }}>
      {children}
    </VideosContext.Provider>
  );
};


