import axios from 'axios';

export const BASE_URL = 'https://youtube-v31.p.rapidapi.com';

const options = {
  params: {
    maxResults: 50,
  },
  headers: {
    'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
    'X-RapidAPI-Host': 'youtube-v31.p.rapidapi.com',
  },
};

export const fetchFromAPI = async (url) => {
    console.log ("full url is " + `${BASE_URL}/${url}`);
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
    for (let i=0; i<data.items.length;i++) {
        console.log ("data.items is " + data.items[i]);
    }

     //Go over all cameras and figure out if they are in database.
    //If not in database, add them.
    //let cameras  = [];
    const date = new Date();
    var numFailures = 0;
    for (let i=0; i<data.items.length; i++) {
      //Json does not like non ascii characters.
      var camName = data.items[i].snippet.title;
      camName = camName.replace(/[^\x00-\x7F]/g, "");

      var description = data.items[i].snippet.description;
      description = camName.replace(/[^\x00-\x7F]/g, "");

      const options2 = {
          "camName": camName,
          "camUrl": "https://www.youtube.com/watch?v="+data.items[i].id.videoId,
          "camLoc": "camLoc",
          "metadata":  description
      };

      axios.post("https://dev.interviewblindspots.com/displaycode/camera/",  options2)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                //dont do anything for error
                //console.log(error);
                numFailures = numFailures + 1;
            });
    }
    console.log ("numSent = " + data.items.length + " numFailures=" + numFailures);

  return data;
};
