import React, { useState, useEffect } from "react";
import NewsletterHeader from "./NewsletterHeader";
import NewsletterSearchBar from "./NewsletterSearchBar";
import NewsletterFilterSidebar from "./NewsletterFilterSidebar";
import NewsletterFooter from "./NewsletterFooter";
import NewsletterCardGrid from "./NewsletterCardGrid";
import NewsletterPagination from "./NewsletterPagination";
import NewsletterLoading from "./NewsletterLoading";
import { Route, Routes } from "react-router-dom";
import NewsletterDetailPage from "./NewsletterDetailPage";
import { useLocation, useNavigate } from "react-router-dom";
import NewsletterLogin from "./NewsletterLogin";

const NewsletterFeed = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchQueryParam = searchParams.get("q");

  const [newsletters, setNewsletters] = useState([]);
  const [filteredNewsletters, setFilteredNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(searchQueryParam || "");
  const [filters, setFilters] = useState({
    brands: {},
    dateRanges: [],
    industries: [],
    howOldInHoursAsStr: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: {},
    dateRanges: {},
    industries: {},
    howOldInHoursAsStr: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Define items per page

  const handleSeachQueryChange = (searchQuery) => {
    setSearchQuery(searchQuery);
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      navigate(location.pathname);
      fetchNewsletters();
      return;
    }
    navigate(`/newsletters?q=${searchQuery}`);
    fetchNewsletters();
  };

  const fetchNewsletters = async () => {
    setLoading(true);
    const response = await fetch(
      `https://dev.interviewblindspots.com/displaycode/newsletterdata${
        searchQuery ? `?q=${searchQuery}` : "/"
      }`
    );
    const { data, ...filtersOptionsData } = await response.json();

    const formattedData = data.map((item) => ({
      title: item.subject || "No title available",
      date: item.sentDate || "No date available",
      age : item.howOldInHoursAsStr || "Age not available",
      //summary: item.summary || "No summary available",
      from: item.from || "No from available",
      brand: item.brand || "No brand available",
      uid: item.uid,
      image: `https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=${item.uid}.jpg`,
      html: item.html || "No html available",
    }));

    setFilterOptions(filtersOptionsData.filters); // Set filter options
    setNewsletters(formattedData);
    setFilteredNewsletters(formattedData);
    setCurrentPage(1); // Reset to the first page on fetch
    setLoading(false);
  };

  useEffect(() => {
    fetchNewsletters();
  }, []);

  const displayedNewsletters = filteredNewsletters.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleFilterChange = (brands, dateRanges, industries, howOldInHoursAsStr) => {
    setFilters({
      brands,
      dateRanges,
      industries,
      howOldInHoursAsStr,
    });
  };

  if (loading) {
    return <NewsletterLoading />;
  }

  return (
    <div className="">
      <NewsletterSearchBar
        searchQuery={searchQuery}
        handleSeachQueryChange={handleSeachQueryChange}
        handleSearch={handleSearch}
      />
      <Routes>
        <Route
          exact
          path=""
          element={
            <>
              <div className="main-content flex mx-auto p-4">
                <NewsletterFilterSidebar
                  filterOptions={filterOptions}
                  onFilterChange={handleFilterChange}
                />
                <NewsletterCardGrid newsletters={displayedNewsletters} />
              </div>
              <NewsletterPagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  filteredNewsletters.length / itemsPerPage
                )}
                onPageChange={setCurrentPage}
              />
            </>
          }
        />
        <Route
          exact
          path=":uid"
          element={<NewsletterDetailPage allarticles={filteredNewsletters} />}
        />
        <Route path="login" element={<NewsletterLogin />} />
      </Routes>
    </div>
  );
};

export default NewsletterFeed;
